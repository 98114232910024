import React, { useState, Fragment, useRef } from "react"
import "../assets/css/looks.scss"
import Arrow from "../../static/arrow.png"
import Look from "../components/look"

export default ({ data }) => {
  let [offset, setOffset] = useState(0)
  let i = 0
  let len = data.length
  let ref = useRef()
  const run = ev => {
    let i = parseInt(ref.current.dataset.i) || 0
    if (ev.target.dataset.dir == "next") {
      if (i < data.length - 1) {
        i += 1
      } else {
        i = 0
      }
    } else {
      if (i > 0) {
        i -= 1
      } else {
        i = data.length - 1
      }
    }
    ref.current.style.transform = `translateX(calc(${i * 100 * -1}%))`
    ref.current.dataset.i = i
    setOffset(i)
  }
  const runClick = i => {
    ref.current.style.transform = `translateX(calc(${i * 100 * -1}%))`
    ref.current.dataset.i = i
    setOffset(i)
  }
  return (
    <Fragment>
      <section className="looks-container">
        <section className="looks">
          <div ref={ref} className="container">
            {data.map((look, idx) => (
              <Look data={look} key={`look-${idx}`} />
            ))}
          </div>
        </section>
        <div onClick={run} data-dir="prev" rel="prev">
          <img src={Arrow} data-dir="prev" />
        </div>
        <div onClick={run} data-dir="next" rel="next">
          <img src={Arrow} data-dir="next" />
        </div>
        <div className="numbers">
          {data.map((look, idx) => (
            <div
              className={`number ${idx === offset ? "active" : ""}`}
              data-id={idx + 1}
              data-i={idx}
              onClick={() => runClick(idx)}
            >
              {idx + 1}
            </div>
          ))}
        </div>
      </section>
    </Fragment>
  )
}
