import React from "react"
import "../assets/css/look.scss"
import { FixedImage } from "../services/images"

export default ({ data }) => {
  return (
    <div className="look">
      <div className="header">
        <h2>{data.title}</h2>
      </div>
      <div dangerouslySetInnerHTML={{ __html: data.text }}></div>
      <div className="look-image">
        {data.image && (
          <FixedImage
            src={data.image.image.id}
            alt={data.image.image.alt}
            options={{ fit: "contain" }}
          />
        )}
      </div>
      <h4>Get this look</h4>
      <div dangerouslySetInnerHTML={{ __html: data.links }}></div>
    </div>
  )
}
