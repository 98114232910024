import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import Feature from "../components/feature"
import FullCTA from "../components/fullCTA"
import ArticlePreview from "../components/articlePreview"
import { BodyText } from "../components/bodyText"
import Looks from "../components/looks"
import { Sort } from "../services/utils"

const Article = ({ data }) => {
  const article = data.tst.Article || {}
  if (!article.pageMetaData) {
    article.pageMetaData = {}
  }
  article.pageMetaData.url = data.url
  const related = []
  let foundIdx = -1
  if (data.tst.allArticles) {
    data.tst.allArticles.some((_article, idx) => {
      if (_article.id === article.id) {
        foundIdx = idx
      }
      if (foundIdx !== -1 && idx > foundIdx && idx <= foundIdx + 3) {
        related.push(_article)
      }

      if (related.length === 2) {
        return true
      }
      return false
    })
  }
  const ctas = Sort(article.ctas || [], article.ctasSort || "")
  return (
    <Layout meta={article.pageMetaData || { title: article.name }}>
      <BodyText article={{ pageTitle: "Style Lookbook" }} className="no-hero" />
      <BodyText
        article={{ ...article, pageTitle: article.pageTitle }}
        secondary={true}
      />

      {article.topCta && (
        <div className="mb-lg">
          <FullCTA data={article.topCta} />
        </div>
      )}

      <Looks data={article.looks} />

      {article.bottomCta && <FullCTA data={article.bottomCta} />}

      <div className="pt-lg lavendar">
        <div className="container full">
          <h3>More lookbooks you might like</h3>
        </div>
      </div>

      <section className="related-articles py-lg">
        {related.map(article => {
          return <ArticlePreview article={article} />
        })}
      </section>

      <div className="talign-center pb-lg lavendar">
        <Link to={`/the-stil-lookbook`}>
          <button className="dark">See All The Lookbooks</button>
        </Link>
      </div>

      {ctas &&
        ctas.length > 0 &&
        ctas.map((cta, idx) => <FullCTA data={cta} key={`s-cta-${idx}`} />)}
    </Layout>
  )
}

export const query = graphql`
  query ArticleQuery($id: ID!) {
    tst {
      Article(where: { id: $id }) {
        id
        name
        pageTitle
        text
        url
        topCta {
          ...CTA
        }
        bottomCta {
          ...CTA
        }
        ctas {
          ...CTA
        }
        ctasSort
        pageMetaData {
          ...Meta
        }
        looks {
          ...Look
        }
      }
      allArticles(sortBy: postedAt_DESC) {
        id
        pageTitle
        url
        image {
          image {
            id
          }
          gravity
          alt
        }
      }
    }
  }
`

export default Article
